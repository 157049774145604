import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import Header from '../components/header'
import Columns from '../components/columns'

const DisclosurePage = () => (
  <Layout>
    <Helmet
      title={'Disclosure'}
      meta={[
        {
          name: 'description',
          content:
            // tslint:disable-next-line:max-line-length
            'Choosing a financial partner is about far more than just finances. Here at Adage Financial, we take pride in the options and services we offer our clients.',
        },
        {
          name: 'keywords',
          content:
            // tslint:disable-next-line:max-line-length
            'Adage Financial Consultants, David Templeton, Little Rock Arkansas, Financial Advisor, Financial Planner, 401k, Safe Money, Annuity',
        },
      ]}
    />
    <Header headline={'Warrantees & Disclaimers'} />

    <Columns background>
      <div className="marketing__column">
        <p>
          There are no warranties implied. Royal Fund Management, LLC (“RFM”) is
          a registered investment adviser located in The Villages, FL. RFM may
          only transact business in those states in which it is registered, or
          qualifies for an exemption or exclusion from registration
          requirements.
        </p>
        <p>
          RFM’s web site is limited to the dissemination of general information
          pertaining to its advisory services, together with access to
          additional investment-related information, publications, and links.
          Accordingly, the publication of RFM’s web site on the Internet should
          not be construed by any consumer and/or prospective client as RFM’s
          solicitation to effect, or attempt to effect transactions in
          securities, or the rendering of personalized investment advice for
          compensation, over the Internet. Any subsequent, direct communication
          by RFM with a prospective client shall be conducted by a
          representative that is either registered or qualifies for an exemption
          or exclusion from registration in the state where the prospective
          client resides.
        </p>
        <p>
          For information pertaining to the registration status of RFM, please
          contact the state securities regulators for those states in which RFM
          maintains a registration filing. A copy of RFM’s current written
          disclosure statement (Form ADV 2A) discussing RFM’s business
          operations, services, and fees is available at the SEC’s investment
          adviser public information website – www.adviserinfo.sec.gov or from
          RFM upon written request.
        </p>
        <p>
          RFM does not make any representations or warranties as to the
          accuracy, timeliness, suitability, completeness, or relevance of any
          information prepared by any unaffiliated third party, whether linked
          to RFM’s web site or incorporated herein, and takes no responsibility
          therefor. All such information is provided solely for convenience
          purposes only and all users thereof should be guided accordingly.
        </p>
        <p>
          This website and information are provided for guidance and information
          purposes only. This website and information are not intended to
          provide investment, tax, or legal advice.
        </p>
        <p>
          The information contained herein has been compiled from sources deemed
          reliable and it is accurate to the best of our knowledge and belief.
          However, RFM cannot guarantee its accuracy, completeness, and validity
          and cannot be held liable for any errors or omissions. Changes are
          periodically made to this web site and may be made at any time.
        </p>
        <p>
          All information contained herein should be independently verified and
          confirmed. RFM does not accept any liability for any loss or damage
          whatsoever caused in reliance upon such information. Readers are
          advised that the material contained herein should be used solely for
          informational purposes.
        </p>
        <p>
          Past performance may not be indicative of future results. Therefore,
          no current or prospective client should assume that future performance
          of any specific investment or investment strategy (including the
          investments and/or investment strategies recommended or undertaken by
          RFM) made reference to directly or indirectly by RFM in its web site,
          or indirectly via a link to an unaffiliated third party web site will
          be profitable or equal the corresponding indicated performance
          level(s). Different types of investments involve varying degrees of
          risk, and there can be no assurance that any specific investment will
          either be suitable or profitable for a client or prospective client’s
          investment portfolio. Historical performance results for investment
          indices and/or categories generally do not reflect the deduction of
          transaction and/or custodial charges, the deduction of an investment
          management fee, nor the impact of taxes, the payment of which would
          have the effect of decreasing historical performance results.
        </p>
        <p>
          Certain portions of RFM’s web site (i.e. newsletters, articles,
          commentaries, etc.) may contain a discussion of, and/or provide access
          to, various positions and/or recommendations made by RFM and/or those
          of other investment and non-investment professionals as of a specific
          prior date. Due to various factors, including changing market
          conditions, such discussions and/or positions may no longer be
          reflective of current position(s) and/ or recommendation(s). Moreover,
          no client or prospective client should assume that any such discussion
          or information presented serves as the receipt of, or a substitute
          for, personalized advice from RFM, or from any other investment
          professional. RFM is not a law firm or accounting firm, and no portion
          of the web site content should be interpreted as legal, accounting or
          tax advice.
        </p>
        <p>
          RFM throughout this website has provided links to various other
          websites. While RFM believes this information to be current and
          valuable to its clients, RFM provides these links on a strictly
          informational basis only and cannot be held liable for the accuracy,
          time sensitive nature, or viability of any information shown on these
          sites.
        </p>
        <p>
          ROYAL FUND MANAGEMENT, LLC IS A REGISTERED INVESTMENT ADVISER.
          INFORMATION PRESENTED IS FOR EDUCATONAL PURPOSES ONLY AND DOES NOT
          INTEND TO MAKE AN OFFER OR SOLICITATION FOR THE SALE OR PURCHASE OF
          ANY SPECIFIC SECURITIES, INVESTMENTS, OR INVESTMENT STRATEGIES.
          INVESTMENTS INVOLVE RISK AND UNLESS OTHERWISE STATED, ARE NOT
          GUARANTEED. BE SURE TO FIRST CONSULT WITH A QUALIFIED FINANCIAL
          ADVISER AND/OR TAX PROFESSIONAL BEFORE IMPLEMENTING ANY STRATEGY
          DISCUSSED HERIN.
        </p>
        <p>
          The content contained herein is for informational purposes only and
          does not constitute a solicitation or offer to sell securities or
          investment advisory services. Investments are not FDIC-insured, nor
          are they deposits of or guaranteed by a bank or any other entity, so
          they may lose value. Past performance is not a guarantee of future
          results and clients should not assume that future performance will be
          comparable to past performance.
        </p>
        <p>
          Our Form ADV Part 2A firm disclosure document is available for review.
          Call or email to request a copy. This document contains detailed
          information about Royal Fund Management, LLC and the services
          provided.
        </p>
        <p>
          A full disclosure can be found,{' '}
          <a href="https://royalfundmanagement.com/disclosure/">here</a>.
        </p>
      </div>
    </Columns>
  </Layout>
)

export default DisclosurePage
